import { React, useState } from 'react';
import styled from 'styled-components'
import Typed from 'react-typed'
import { animated } from 'react-spring';
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import 'react-typed/dist/animatedCursor.css';
import phoneIcon from './smartphone-call.png';
import emailIcon from './email.png';
import locationIcon from './placeholder.png'
import downloadIcon from './file.png'
import useBoop from './useBoop'

const Header = () => {
    const [phoneStyle, phoneTrigger] = useBoop({ rotation: 45 });
    const [emailStyle, emailTrigger] = useBoop({ x: 5, rotation: 15 });
    const [locationStyle, locationTrigger] = useBoop({ y: -10 })
    const [downloadStyle, downloadTrigger] = useBoop({ y: -10 })

    return (
        <StyledHeader>
            <div>
                <Name>Travus Helmly</Name>
                <div>A software engineer who can do:</div>
                <Typed
                    loop
                    strings={[
                        'Front-End Development',
                        'Back-End Development',
                        'Fullstack Development',
                        'UX/UI Design',
                        'Web Development',
                        'Rapid Prototyping',
                        'Cloud Development'
                    ]}
                    typeSpeed={40}
                />
            </div>
            {isBrowser ?
                (
                    <ContactInfoDesktop>
                        <Location onMouseEnter={locationTrigger}><animated.img style={locationStyle} src={locationIcon} alt='location' /> Raleigh, NC</Location>
                        <Phone onMouseEnter={phoneTrigger}><animated.img style={phoneStyle} src={phoneIcon} alt='phone number' /> <A href='tel:+18438101350'>843-810-1350</A></Phone>
                        <Email onMouseEnter={emailTrigger}><animated.img style={emailStyle} src={emailIcon} alt='email' /> <A href="mailto:helmlyw@gmail.com">helmlyw@gmail.com</A></Email>
                        <Download onMouseEnter={downloadTrigger}><animated.img style={downloadStyle} src={downloadIcon} alt='download as pdf' />Download As PDF</Download>
                    </ContactInfoDesktop>
                ) :
                (
                    <ContactInfoMobile>
                        <a href="https://www.google.com/maps/place/Raleigh,+NC/@35.8436867,-78.785143,11z/data=!3m1!4b1!4m5!3m4!1s0x89ac5a2f9f51e0f7:0x6790b6528a11f0ad!8m2!3d35.7795897!4d-78.6381787" target="_blank"><img src={locationIcon} alt='location' /></a>
                        <a href='tel:+18438101350'><img src={phoneIcon} alt='phone number' /></a>
                        <a href="mailto:helmlyw@gmail.com"><img src={emailIcon} alt='email' /></a>
                        <img src={downloadIcon} alt='download as pdf' />
                    </ContactInfoMobile>
                )
            }
        </StyledHeader>
    )
}

const StyledHeader = styled.div`
    padding: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom-style: solid;
    border-color: black;
`

const Name = styled.div`
    font-size: 3rem;
`

const ContactInfoDesktop = styled.div`
    font-size: 1.2rem;
`
const ContactInfoMobile = styled.div`
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
`
const A = styled.a`
    text-decoration: none;
    color: inherit;
`
const Phone = styled.div`
`
const Email = styled.div`
`
const Location = styled.div`
`
const Download = styled('div')`
`

export default Header