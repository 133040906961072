import Theme from './Theme.js'
import Header from './Header/Header.js'
import Body from './Body/Body.js'

function App() {
  return (
    <Theme>
      <div>
        <Header />
        <Body />
      </div>
    </Theme>
  );
}

export default App;
