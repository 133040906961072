import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    // Muted Sophisticated
    c1: "#B7D8D6",
    c2: "#789E9E",
    c3: "#4D6466",
    c4: "#EEF3DB",
    c5: "#FE615A"
  },
  fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em"
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;