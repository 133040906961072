import { React, useState } from 'react';
import styled from 'styled-components';
import Tree from './Tree/Tree.js'

const Body = () => {
    return (
        <StyledBody>
            <Tree name="About Me" defaultOpen>
                <Tree name="Professional Summary">
                    <div>5 years of experience in Web Development, Designing User Interface Applications, Testing and documentation of professional web based applications</div>
                    <div>Experience with designing, developing, and testing serverless applications</div>
                    <div>Configured, administered, and developed cloud solutions on Amazon Web Services</div>
                    <div>Worked with clients from scratch (UI/UX design) to developing, testing, deploying, debugging, and supporting applications</div>
                    <div>Experience with prototyping with codeless platforms such as Bubble</div>
                    <div>Experience creating custom responsive components in React</div>
                    <div>Designed and developed RESTful APIs</div>
                    <div>Debugged Spring applications</div>
                    <div>Experience developing and debugging software on various microcontrollers and e-paper modules</div>
                    <div>Developed with both the Unity and Unreal engines</div>
                </Tree>
                <Tree name="Technical Skills" >
                    <div>
                        Languages: JavaScript, Java, SQL, C#, C++, C
                    </div>
                    <div>
                        Front-End Development: React, Styled-Components, SASS, React-Spring, CSS, Jest, Tailwind, Google APIs, Here Maps
                    </div>
                    <div>
                        Back-End Development: Node, Spring, Serverless, RESTful APIs, Stripe, AWS, MySQL, NoSQL 
                    </div>
                    <div>
                        Cloud Technologies: AWS (Lambda, Cognito, S3, CloudFront, Route 53, SES, DynamoDB, RDS, Elastic Beanstalk, Cloudwatch)
                    </div>
                    <div>
                        Databases: MySQL, DynamoDB
                    </div>
                    <div>
                        IDEs: Visual Studio Code, Eclipse
                    </div>
                    <div>
                        Version Control: Git, Subversion
                    </div>
                    <div>
                        Methodologies: Waterfall, Agile, Behavior and Test Driven Development
                    </div>
                </Tree>
                <Tree name="Experience">
                    <Tree name="SAPO">
                        <div>Software Engineer November 2017-Present</div>
                        <div><a href='https://sapopros.com'>SAPO</a> is a cloud-based field service solution for restore and consignment stores.  SAPO helps stores manage their booking, scheduling, dispatching, and logistics.</div>
                        <ul>
                            <li>Designed, Developed, Tested, Deployed, and Support the multiple SAPO Applications</li>
                            <li>Developed the client side user interface as a Progressive Web Application (PWA) using React.js</li>
                            <li>Designed the server side as a serverless REST API using Node.js and AWS Lambda.</li>
                            <li>Modeled our data with both AWS DynamoDB (NoSQL) and AWS RDS (SQL) services.</li>
                        </ul>
                    </Tree>
                    <Tree name="Veterans And Family Windows And Doors">
                        <div>IT Operations and Dock Builder March 2020-December 2020</div>
                        <div><a href='https://vfwindows.com'>Veterans And Family</a> is a window and door company.</div>
                        <ul>
                            <li>Setup the basic IT operations of the company using G Suite.</li>
                            <li>Started to build CRM processes using Salesforce. </li>
                            <li>Worked as an installer on a multi-dock project.</li>
                        </ul>
                    </Tree>
                    <Tree name="Bluez">
                        <div>Software Developer September 2016-October 2017</div>
                        <div>
                            <ul>
                                <li>Worked on an unfinished video game project using the Unity (C#) and Unreal engines (C++ and Blueprint templates).</li>
                                <li>Prototyped a board game piece that displayed a player avatar on an e-paper display.  The e-paper display would update based on updates from NFC embedded game card scans.</li>
                            </ul>
                        </div>
                    </Tree>
                    <Tree name="Booz Allen Hamilton">
                        <div>Junior Software Developer Nov 2015-May 2016</div>
                            <ul>
                                <li>Fixed bugs in Java and C++ for an air traffic support project.</li>
                                <li>Wrote unit tests for legacy code.</li>
                                <li>Created a test environment for the MySQL solution.</li>
                                <li>Updated the documentation for the virtual environment setup.</li>
                            </ul>
                    </Tree>
                </Tree>
                <Tree name="Education">
                    <Tree name="North Carolina State University">
                        <div>Bachelor of Science in Electrical Engineering</div>
                        <div>Honors: Cum Laude</div>
                    </Tree>
                    <Tree name="University of South Carolina">
                        <div>Bachelor of Arts in Classical Languages (Ancient Greek And Latin) and Comparative Literature</div>
                        <div>Honors: Magna Cum Laude, Honors College Graduate</div>
                    </Tree>
                </Tree>
                <Tree name='Interests'>
                    <div>Grappling</div>
                    <div>Automating Duck Farming</div>
                </Tree>
            </Tree>
        </StyledBody>
    )
}

const StyledBody = styled.div`
    padding: 3rem;
`

export default Body